<template>
  <div class="tm-slideProductCard-root">
    <PopularPrice
      class="popular-price --mob"
      :flat-name="productName"
      :flat-number="flat.name"
      :is-hit="flat.isHit"
      :old-price="flat.oldPrice"
      :price="productCharacteristicsMain.find(t=> t.name === 'groupPlaningPrice')?.value || ''"
      :property-area="propertyArea?.value"
    />
    <div class="divider" />
    <div class="tm-slideProductCard__image">
      <nuxt-img
        v-if="flat.imageId"
        :alt="altTitle"
        :title="altTitle"
        :src="String(flat.imageId)"
        loading="lazy"
        width="555"
        height="342"
        fit="contain"
        placeholder
      />
      <template v-else>
        <img
          v-if="flat.characteristicGroupId === 5"
          src="~/assets/img/jpg/plug-parking.jpg"
          class="product-card-grid__image-plug"
          alt="cover"
        />

        <img
          v-else
          src="~/assets/img/jpg/plug.jpg"
          class="product-card-grid__image-plug"
          alt="cover"
        />
      </template>
    </div>
    <div class="divider" />
    <div class="tm-slideProductCard__left">
      <div class="tm-slideProductCard__info">
        <PopularPrice
          class="popular-price --desc"
          :flat-name="productName"
          :flat-number="flat.name"
          :is-hit="flat.isHit"
          :old-price="flat.oldPrice"
          :price="productCharacteristicsMain.find(t=> t.name === 'groupPlaningPrice')?.value || ''"
          :property-area="propertyArea?.value"
        />
        <div class="tm-slideProductCard__description">
          <template
            v-for="(characteristic, index) in productCharacteristicsMain"
            :key="`${characteristic.value}-${altTitle}-${index}`"
          >
            <div
              v-if="isShowCharacteristicsInTemplate(characteristic.name)"
              class="chars-row"
            >
              <span class="label">{{ characteristic.label }}</span>
              <span
                class="value"
                v-html="characteristic.value"
              ></span>
            </div>
          </template>
        </div>
      </div>
      <div class="slider-product__features">
        <FeaturesProduct
          v-if="featuresProducts && featuresProducts.length"
          :features-props="featuresProducts"
          :characteristic-group-id="flat.characteristicGroupId || null"
        />
      </div>

      <div class="bottom-left">
        <WishBtn :product-id="Number(flat.id)" />
        <ShareComponent
          class="bottom-left__share"
          :link-props="fullLinkToCurrentFlat"
        />
        <div
          class="btn primary --shadow btn-flat"
          @click="onNavigateToFlat"
        >
          Подробнее (найдено: {{ productCharacteristicsMain.find(t=> t.name === 'groupPlaningCount')?.value || '' }})
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import { getProductCharacteristicsMain, getProductLabel } from '~/utils';
import FeaturesProduct from '~/components/product/FeaturesProduct.vue';
import { computed } from '#imports';
import ShareComponent from '~/components/wigets/ShareComponent.vue';
import WishBtn from '~/components/wigets/WishBtn.vue';

const props = defineProps<{
	flat:IProduct;
}>();

const { $priceFormat } = useNuxtApp();

const isShowCharacteristicsInTemplate = (chars:string) => [
  'privateHouseName',
  'groupPlaningFloor',
  'houseDevelopmentEnd',
  'ceilingHeight'
].includes(chars);

const altTitle = computed(() => [props.flat?.name, props.flat?.code].filter((t) => !!t).join(' '));

const productName = computed(() => {
  const characteristics = (props.flat.characteristics || [])?.[0]?.characteristics || [];

  const productRooms = (characteristics || []).find((t) => t.slug === 'rooms')?.values?.[0] || null;
  if (!productRooms) {
    return `${groupProductLabel.value}`;
  }
  if (productRooms.slug === 'studiya') {
    return 'Студия';
  }

  return `${productRooms?.value}-к ${groupProductLabel.value}`;
});

const propertyArea = computed(() => productCharacteristicsMain.value.find((t) => t.name === 'propertyArea'));

const productCharacteristicsMain = computed(() => {
  const characteristics = (props.flat.characteristics || [])?.[0]?.characteristics || [];
  return getProductCharacteristicsMain(characteristics);
});

const featuresProducts = computed(() => props.flat.characteristics.filter((t) => t.name === 'Особенности'));

const groupProductLabel = computed(() => {
  const { characteristicGroupId } = props.flat;
  return characteristicGroupId ? getProductLabel(characteristicGroupId) : '';
});

const fullLinkToCurrentFlat = computed(() => `https://${window.location.host}${props.flat?.url}`);

const onNavigateToFlat = () => {
  navigateTo(props.flat?.url);
};

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.tm-slideProductCard-root {
  background: #FFF;
		display: flex;
		gap: 10px;
		border-radius: 20px;

	& > div{
		width: 50%;
	}

	& .divider{
		padding-bottom: 16px;
		margin-bottom: 16px;
		width: 100%;
		border-bottom: 1px solid var(--primary-15);
		display: none;
	}

	& .tm-slideProductCard__left{
			position: relative;
		padding: 40px;

		&::before{
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 1px;
			height: 100%;
			background: var(--primary-15);
		}
	}

	& .popular-price{
		&.--mob{
			display: none;
		}

		&.--desc{
			display: flex;
		}
	}

	& .btn-flat{
		min-width: 200px;
		font-size: 16px;
		padding: 4px 24px;
	}

	& .bottom-left{
		display: flex;
		align-items: center;
		gap: 12px;
		margin-top: 32px;

		&:deep(.wish-btn){
			border-radius: 50%;
			padding: 0;
			border: 1px solid #F1F0F3;
			width: 48px;
			height: 48px;
			min-width: 48px;
			box-shadow: 0 0 0 0 rgb(0 0 0 / 3%), 0 1.561px 3.122px 0 rgb(0 0 0 / 3%), 0 5.463px 5.463px 0 rgb(0 0 0 / 3%), 0 12.488px 7.024px 0 rgb(0 0 0 / 2%), 0 21.854px 8.585px 0 rgb(0 0 0 / 0%), 0 34.341px 9.366px 0 rgb(0 0 0 / 0%);

			& .wish-btn__content > span{
				display: none;
			}
		}

		&:deep(.bottom-left__share){
			& .share-block{
				bottom: calc(100% + 4px);
				top: initial;
				z-index: 20;
				left: 35px;
				right: initial;
			}

			& .details-btn{
				width: 100%;
				height: 48px;
				border-radius: 23px;
				border: 0.39px solid #F1F0F3;
				background: rgb(255 255 255 / 50%);
				box-shadow: 0 0 0 0 rgb(0 0 0 / 3%), 0 1.561px 3.122px 0 rgb(0 0 0 / 3%), 0 5.463px 5.463px 0 rgb(0 0 0 / 3%), 0 12.488px 7.024px 0 rgb(0 0 0 / 2%), 0 21.854px 8.585px 0 rgb(0 0 0 / 0%), 0 34.341px 9.366px 0 rgb(0 0 0 / 0%);
				min-width: 200px;

				& > span{
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 125% */
					letter-spacing: -0.32px;
					color: var(--primary-80);
				}
			}
		}
	}
}

.tm-slideProductCard__image {
  display: flex;
  justify-content: center;
  align-items: center;
		width: 100%;
		height: 422px;
		padding: 40px ;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & > .product-card-grid__image-plug {
    border-radius: 10px;
    object-fit: cover;
  }
}

.tm-slideProductCard__info{
	min-width: 460px;
}

.slider-product__features{
	&:deep(.features-product__root){
		justify-content: start;
		margin-top: 0;

		& >div{
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 114.286% */
			letter-spacing: -0.14px;
			padding: 8px 18px;
			background: var(--primary-10);
			border-color: transparent;
		}
	}
}

.tm-slideProductCard__description {
  display: flex;
		flex-wrap: wrap;
		margin-top: -16px;
		margin-left: -40px;
		margin-bottom: 30px;
		position: relative;

	&::before{
		content: '';
		position: absolute;
		width: 100%;
		background: var(--white);
		height: 4px;
		bottom: 0;
		left: 1px;
	}

	& > div{
		width: calc(100% / 2 - 40px);
		margin-left: 40px;
		margin-top: 16px;
		padding-bottom: 15px;
		border-bottom: 1px solid var(--primary-15);
	}

	& .chars-row{
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	& .label{
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 110%; /* 16.5px */
		letter-spacing: -0.3px;
		color: var(--primary-60);
	}

	& .value{
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 110%; /* 19.8px */
		letter-spacing: -0.36px;
		color: var(--primary);
	}
}

.tm-slideProductCard__square,
.tm-slideProductCard__price {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--primary-80);
}

.product-card-grid__image-plug {
  border-radius: 10px;
  max-width: 420px;

}

@include media('xl') {
	.tm-slideProductCard-root{
		& .bottom-left{
			flex-wrap: wrap;
		}

		& .details-share{
			display: flex;
			flex: 1;
		}

		& .btn-flat{
			width: 100%;
		}
	}
}

@include media('lg') {
	.tm-slideProductCard-root{
		& .bottom-left{
			flex-wrap: wrap;
		}

		& .btn-flat{
			width: 100%;
		}
	}

 .details-share{
		display: flex;
		flex: 1;
	}

	.tm-slideProductCard__info{
		min-width: initial;
	}
}

@include media('md') {
	.tm-slideProductCard-root{
		flex-direction: column;
		padding: 20px;
		height: 100%;

		& .bottom-left{
			margin-top: auto;
		}

		& .divider{
			display: flex;
		}

		& .popular-price{
			&.--mob{
				display: flex;
				padding: 0;
			}

			&.--desc{
				display: none;
			}
		}

		& > div {
			width: 100%;
		}

		& .tm-slideProductCard__left{
			padding: 0;
			display: flex;
			flex-direction: column;
			height: 100%;

			&::before{
				content: none;
			}
		}
	}

	.tm-slideProductCard__image{
		padding: 0 20px;
		height: 310px;
	}

	.slider-product__features{
		&:deep(.features-product__root){
			& >div{
				font-size: 12px;
				line-height: 100%;
				padding: 4px 18px;
			}
		}
	}
}

@include media('sm') {
	.tm-slideProductCard__image {
	  height: 210px;
  }

  .tm-slideProductCard__square,
  .tm-slideProductCard__price {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
  }

	.tm-slideProductCard__description {
		display: flex;
		flex-wrap: wrap;
		margin-top: -15px;
		margin-left: 0;
		margin-bottom: 15px;
		position: relative;

		&::before{
			content: none;
		}

		& > div{
			width: 100%;
			margin-left: 0;
			margin-top: 15px;
			padding-bottom: 0;
			border-bottom: none;
		}

		& .chars-row{
			flex-direction: row;
			gap: 5px;
			align-items: center;
		}

		& .label{
			font-size: 14px;
		}

		& .value{
			font-size: 15px;
		}
	}
}
</style>
